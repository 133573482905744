<template>
  <div class="cart-list preview">
    <div v-if="cartList.items && cartList.items.length">
      <div class="cart-list_title" v-if="hasAttractions">
        <h3 class="sub-title">Attractions</h3>
      </div>
      <div class="m_b-16"  v-for="(item, index) in cartList.items" :key="'0' + index">
        <div v-if="item.type === 'activity'">
          <cart-item-preview :cart-item="item" :hide-delete="hideDelete" :meta="cartErrors.length ? cartErrors : null" />
          <div v-if="cartErrors.length">
            <div class="v-messages theme--light error--text mt-2 ml-2" v-if="getErrorByUid(item.uid)">
              <div class="v-messages__message">{{getErrorByUid(item.uid).detail}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="cart-list_title" :class="{'mt-8': hasAttractions}" v-if="hasHotels">
        <h3 class="sub-title">Hotels</h3>
      </div>
      <div  v-for="(item, index) in cartList.items" :key="'1' + index">
        <div v-if="item.type === 'hotel'">
          <hotel-cart-item-preview :cart-item="item" :hide-delete="hideDelete" />
          <div v-if="cartErrors.length">
            <div class="v-messages theme--light error--text mt-2 ml-2" v-if="getErrorByUid(item.uid)">
              <div class="v-messages__message">{{getErrorByUid(item.uid).detail}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="cart-list_title" :class="{'mt-8': hasHotels || hasAttractions}" v-if="hasEvents">
        <h3 class="sub-title">Events</h3>
      </div>
      <div  v-for="(item, index) in cartList.items" :key="'2'+ index">
        <div v-if="item.type === 'event'">
          <event-cart-item-preview
            v-for="childItem in item.items"
            :key="childItem.uid"
            :cart-item="{...childItem, ...item}"
            :hide-delete="hideDelete"
          />
          <div v-if="cartErrors.length">
            <div class="v-messages theme--light error--text mt-2 ml-2" v-if="getErrorByUid(childItem.uid)">
              <div class="v-messages__message">{{getErrorByUid(childItem.uid).detail}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="empty-cart">
        <img src="/img/cart/empty-cart.svg" alt="">
        <h2>It seems you don't have anything in your cart</h2>
        <v-btn class="mt-8" color="primary" depressed large to="/" @click="goToSearch">Let's Trip!</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import HotelCartItemPreview from '@/components/HotelsComponents/HotelCartItem/HotelCartItemPreview'
import EventCartItemPreview from '@/components/EventCartItem/EventCartItemPreview'
import CartItemPreview from '@/components/CartItem/CartItemPreview'
import EmptyResults from '@/components/EmptyResults/EmptyResults'

export default {
  props: ['cart', 'hideDelete'],
  components: {
    HotelCartItemPreview,
    EventCartItemPreview,
    EmptyResults,
    CartItemPreview,
    /*HotelCartItemPreview,
       EventCartItemPreview*/
  },
  computed: {
    hasAttractions() {
      return this.cart.items.find(item => item.type === 'activity')
    },
    hasHotels() {
      return this.cart.items.find(item => item.type === 'hotel')
    },
    hasEvents() {
      return this.cart.items.find(item => item.type === 'event')
    },
    cartList() {
      let rez = Object.assign({}, this.cart)

      rez.items.forEach(item => {
        if (item.type === 'event') {
          item.items.map(itemOfItem => {
            itemOfItem.name = item.name
            itemOfItem.time = item.time
            itemOfItem.venue_address = item.venue_address
            itemOfItem.venue_name = item.venue_name
            itemOfItem.date = item.date

            return itemOfItem
          })
        }
      })

      return rez
    },
    cartErrors() {
      return this.$store.state.cartPageErrors
    }
  },
  methods: {
    goToSearch() {
      this.$emit('goToSearch')
    },
    getErrorByUid(uid) {
      const error = this.cartErrors.find(err => {
        return err.meta.item_uid == uid
      })

      return error
    }
  },
  mounted() {
    this.$vuetify.goTo(0)
  }
}

</script>

<style lang="scss" scoped>
  .cart-list {
    max-width: 890px;
    width: 100%;
    margin: 0 auto;

    &.preview {
      .sub-title {
        font-weight: 600;
        font-size: 20px;
        line-height: 100%;
        text-transform: capitalize;
        color: #000000;
      }

      .cart-item {
        border: none;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1)
      }
    }
  }

  .empty-cart {
    text-align: center;
    $text-color: #424D63;

    h2 {
      font-weight: bold;
      font-size: 23px;
      line-height: 34px;
      color: $text-color;
      margin-top: 80px;
    }

    h3 {
      font-weight: 600;
      font-size: 15px;
      line-height: 20px;
      color: $text-color;
      margin-top: 16px;
      margin-bottom: 32px;
    }
  }
</style>

<style lang="scss">


  .cancellation-dropdown-wrap {
    position: relative;
  }

  .cancellation-dropdown-link {
    cursor: pointer;
    display: flex;
    align-items: center;
  .text {
    text-decoration: underline;
  }

  & > span {
        display: flex;
      }
  }

  .cancellation-dropdown  {
    position: absolute;
    background: #FFFFFF;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 24px 40px;
    flex-direction: column;
    top: 44px;
    width: max-content;
    max-width: 500px;
    right: 0;
    z-index: 1001;
    display: none;

  &.open {
     display: flex;
   }
  }

  .cancellation-dropdown-item {
    display: flex;
    align-items: center;

    &_icon {
       margin-right: 16px;
     }

    &_text {
       text-align: left;
    }
  }
</style>
