<template>
  <div ref="container" id="cookie-message">
    <div class="container">
      <div class="inner-container">
        <div class="message">
          <div class="icon">
            <img src="@/assets/img/cookies.png" class="cookies-image" alt="cookies">
          </div>
          <span class="text">
            This site uses cookies to provide you with the best user experience. By using {{$texts.brand_name}}, you accept our <nuxt-link to="/terms-and-conditions" target="_blank">Terms &amp; Conditions</nuxt-link> and <nuxt-link to="/privacy-policy" target="_blank">Privacy Policy</nuxt-link>.
          </span>
        </div>
        <div class="btn-wrapper">
          <!-- <button type="button" class="btn decline">
            <span class="btn-content">DEcline</span>
          </button> -->
          <button type="button" class="btn" @click="acceptGDPR">
            <span class="btn-content">Accept all</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  mounted() {
    this.heightObserver = new ResizeObserver(entries => {
      entries.forEach(entry => {
        this.updateHeight()
      })
    })
    this.heightObserver.observe(this.$refs.container)
  },
  beforeDestroy() {
    this.heightObserver.disconnect()
  },
  methods: {
    acceptGDPR() {
      this.$store.commit('SET_COOKIES_BANNER_HEIGHT', 0)
      this.heightObserver.disconnect()
      window?.acceptGDPR?.()
    },
    updateHeight() {
      const height = this.$refs.container.offsetHeight
      this.$store.commit('SET_COOKIES_BANNER_HEIGHT', height)
    }
  },
  components: {
    ResizeObserver: () =>  import('resize-observer-polyfill')
  }
}
</script>
<style lang="css">
#cookie-message {
  display: block;
  z-index: 1002;
  background-color: white;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 1rem 0;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.3);
}
#cookie-message .container {
  position: relative;
  display: flex;
}
#cookie-message .inner-container {
  width: 100%;
  margin: 0 auto;
  height: auto;
  display: flex;
  justify-content: space-around;
}
#cookie-message .message {
  color: #424d63;
  font-weight: 600;
  padding: 0 1rem;
}
#cookie-message .text {
  font-size: 0.9333333333rem;
  line-height: 1.3333333333rem;
}
#cookie-message a {
  text-decoration: none;
  color: #08B5FF;
}
#cookie-message .icon {
  width: 3.3rem;
  display: flex;
  align-items: center;
}
#cookie-message .btn-wrapper {
  display: flex;
  align-items: center;
}
#cookie-message .btn {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  font-weight: 500;
  letter-spacing: .0892857143em;
  justify-content: center;
  outline: 0;
  position: relative;
  text-decoration: none;
  text-indent: 0.0892857143em;
  text-transform: uppercase;
  transition-duration: .28s;
  transition-property: box-shadow,transform,opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  background-color: #000;
  border-color: #000;
  font-size: .875rem;
  color: #fff;
  height: 2.6rem;
  min-width: 78px;
  padding: 0 20px;
  border-radius: 4px;
  cursor: pointer;
}
#cookie-message .btn-content {
  text-transform: capitalize;
  font-size: 15px;
  letter-spacing: initial;
  font-weight: 600;
}
@media screen and (max-width:767px) {
  #cookie-message {
    box-shadow: none;
    border-top: 1px solid #ECEFF0;
  }
  #cookie-message .container {
    flex-direction: column;
  }
  #cookie-message .inner-container {
    flex-direction: column;
  }
  #cookie-message .message {
    padding: 0 0 .25rem;
  }
  #cookie-message .text {
    font-size: 0.75rem;
    line-height: 1.021rem;
  }
  #cookie-message .icon {
    width: 24px;
    display: flex;
    position: absolute;
    top: -1.01rem;
    left: 1.06667rem;
    transform: translateY(-50%);
  }
  #cookie-message .icon img {
    height: 24px;
    width: 24px;
  }
  #cookie-message .btn {
    margin-left: auto;
    width: 50%;
    max-width: 150px;
  }
  #cookie-message .btn-content {
    font-weight: 700;
    font-size: 14px;
    line-height: 100%;
    text-transform: uppercase;
  }
}
@media screen and (min-width:768px) {
  #cookie-message {
    padding: 2rem 0;
  }
  #cookie-message .inner-container {
    align-items: center;
    justify-content: space-between;
  }
  #cookie-message .message {
    display: flex;
    max-width: 80%;
    padding: 0;
  }
  #cookie-message .text {
    font-size: 0.875rem;
    line-height: 1.375rem;
  }
  #cookie-message .icon {
    margin-right: 24px;
    width: 36px;
  }
  #cookie-message .icon img {
    width: 36px;
    height: 36px;
  }
  #cookie-message .btn-content {
    font-size: 20px;
    line-height: 100%;
    text-transform: uppercase;
  }
}
</style>
